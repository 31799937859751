import axios from "axios";
import { URL_BASE } from "../../routes/routes";
import { DisplayError, Error, Success } from "../alerts";
import "../../types/typedef/documents";
import "../../types/typedef/documents";
import "../../types/typedef/components/preinvoice";
import "../../types/typedef/tcp";
import {
  base64ToFile,
  fileArrayToFileList,
  xmlFileToJson,
} from "helpers/files";
import { getInvoiceValidations } from "./parameters";
import { arrayToQueryParam, isValidHttpResCode, successRes } from "./fetch";
import { saveAs } from "file-saver";
import { getUser } from "./usuariosApi";
import { getEnterpriseInfo } from "./setUp";
import { parseToFolio } from "helpers/documents";
import { downloadFileFromBlobV3 } from "./associateFilesV2";
import {
  dateToDbFormat,
  getDateFromUtc,
  getFirstDayOfMonth,
  parseDateToText,
} from "helpers/dates";
// import n2words from "n2words/lib/n2words.js";
import n2words from "n2words";
import { getErrorData } from "helpers/errors";
import { array } from "prop-types";

/**
 * Fetch the document items of a document (v2)
 *
 * @param {number|string} idDocument - Id of the document to query it items
 * @returns {object[]}
 */
export async function getDocumentItems(idDocument) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}documentos/partidas/${idDocument}`
    );

    return data.items;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getDocumentItemsFix(idDocument) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}documentos/documento/${idDocument}/partidas`
    );

    return data.documentItem;
  } catch (error) {
    console.log(error);
    return [];
  }
}

/**
 * Get the information to fill the information to win quotation
 * @param {(number|string)} idQuote - Id of the quote to win
 * @returns {object}
 */
export async function GetInfoWinQuote(idQuote) {
  try {
    const { data } = await axios.get(`${URL_BASE}documentos/ganar/${idQuote}`);

    return data.data;
  } catch (error) {
    console.log(error);
    Error(() => {}, "Asegurate de tener conexion a internet");
  }
}

/**
 * Update the tc requested on the preinvoice to stamp
 *
 * @param {number} idDocument - Id of the preinvoice to update the tc request
 * @param {number} tc - Tc requested by the executive
 * @returns {boolean} True if the tc was updated on the request
 */
export async function UpdateTcRequest(idDocument, tc) {
  try {
    await axios.put(`${URL_BASE}documentos/prefactura/tc`, {
      idDocument,
      tc,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

/**
 * Update the flag authorization of the preinvoice
 *
 * @param {number} idDocument - Id of the preinvoice to update
 * @param {PreinvoiceFlags} idFlag - Authorization ids to update preinvoice stamp
 * @param {string|null} limitTime - ISO string of the limit time to bill the preinvoice if gets authorizated
 * @returns {UpdateAuthPreinvoiceRes} Response of the update
 */
export async function UpdatePreinvoiceAuth(
  idDocument,
  idFlag,
  limitTime = null
) {
  try {
    await axios.put(`${URL_BASE}documentos/prefactura/autorizacion`, {
      idDocument,
      idFlag,
      limitTime,
    });

    return {
      wasUpdated: true,
      message: "Autorizacion de prefactura actualizada",
    };
  } catch (error) {
    console.log(error);

    return {
      wasUpdated: false,
      error,
    };
  }
}

/**
 * @typedef DocumentTypes
 * @property {number} documentTypeID - ID of the type document
 * @property {string} description - Name of the type document
 */

/**
 * Get the type documents of the system
 * @returns {DocumentTypes[]} JSON with the type documents
 */
export async function GetDocumentTypes() {
  let data = await fetch(`${URL_BASE}documentos/tipos`);
  data = await data.json();
  return data.documentTypes;
}

export async function GetMyActiveCustomers(userID) {
  const data = await axios.get(
    `${URL_BASE}documentos/getmyactivecustomers?userID=${userID}`
  );

  if (data.data.status === 200) {
    return data.data.myCustomers;
  }
  return [];
}

/**
 * Fetch the contacts of a customer
 *
 * @param {number|string} customerID - Id of the customer
 * @returns {Promise<import("../../../../types/contacts").ContactComboI[]>} contacts - Contacts that has that customer or provider
 */
export async function GetMyActivCustomersContacts(customerID = 1) {
  try {
    if (typeof customerID !== "number") return [];

    if (isNaN(customerID)) return [];

    if (customerID <= 0) return [];

    /**
     * @type {import("axios").AxiosResponse<import("../../../../server/controllers/controllerContactsTypes.d.ts").ResContactCombo>}
     */
    const data = await axios.get(
      `${URL_BASE}documentos/getmyactivecustomerscontacts?customerID=${customerID}`,
      {
        withCredentials: true,
      }
    );

    if (data.status === 200) {
      return data.data.myCustomerInfoContacs;
    }
    return [];
  } catch (error) {
    Error(() => {}, error);

    return [];
  }
}

/**
 * Information to create when button "Ganar" it's triggered
 * @param {import("types/typedef/customHooks/useWinQuote").WinQuoteDTO} info - Information in order to win the quote
 * @returns {boolean} True if document was generated successfully
 */
export async function WinQuoteV2(info) {
  try {
    await axios.post(
      `${URL_BASE}documentos/cotizacion/ganar/v2`,
      {
        quote: info.quote,
        oc: info.oc,
        contract: info.contract,
        executive: info.executive,
        preinvoice: info.preinvoice,
      },
      {
        withCredentials: true,
      }
    );

    Success(() => {}, "Cotizacion ganada");

    return true;
  } catch (error) {
    const { message, errorCode } = error.response.data;
    console.log(error);
    Error(() => {}, `Codigo de error: ${errorCode}. ${message}`);
    return false;
  }
}

/**
 * Check if the TCP used on the document is valid in order to create the invoice against SAT
 * @param {number} tcp - TCP used on the document tried to be validated
 * @returns {import("./typesDocuments").MinTcInvoiceValidation} True if the tc used is valid for the enterprise before create a invoice against sat
 */
export async function validateTcAgainstEnterprise(tcp) {
  const [{ saiko }, { variance }] = await Promise.all([
    GetTcp(),
    getInvoiceValidations(),
  ]);

  const minTcToUse = saiko - variance;

  if (tcp >= minTcToUse)
    return {
      isValid: true,
      minTc: minTcToUse,
    };
  return {
    isValid: false,
    minTc: minTcToUse,
  };
}

/**
 * Get the full information of an specific document
 *
 * @param {number} id - Id of the document to fetch
 * @returns {Promise<import("../../types/typedef/documents").DocumentI>} Info of the document
 */
export async function GetDocument(id = null) {
  if (!id) {
    return null;
  }

  try {
    const { data } = await axios.get(`${URL_BASE}documentos/documento/${id}`);

    if (data.status === 200) {
      return data.documentInfo;
    } else {
      Error(() => {}, data.error);
    }
  } catch (error) {
    Error(() => {}, error);
  }
}

export async function GetProbability() {
  const data = await axios.get(`${URL_BASE}documentos/getprobability`);

  if (data.data.status === 200) {
    return data.data.probability;
  }
  return [];
}

/**
 * @typedef StatusDocuments
 * @property {number} state - ID of the status
 * @property {number} document - FK of the document associated with
 * @property {string} description - Description to show on the UI
 * @property {number} order - Order must be appear on the system (ASC to DESC)
 * @property {number} logicalErase - 0 if the element is not erased
 */

/**
 * Get a list of the status from a determinated document
 *
 * @param {number} id - ID of the document status to request
 * @returns {StatusDocuments} - Status of the document requested
 */
export async function GetStatusDocument(id) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}documentos/estados-documentos/${id}`
    );

    return data.statusDocuments;
  } catch (error) {
    Error(null, error);
  }
}

/**
 * Get the catalogue of products/services
 * @returns {Catalogue[]} Catalogue
 */
export async function GetCatalogue() {
  try {
    const { data } = await axios.get(`${URL_BASE}documentos/catalogo`);

    return data;
  } catch (error) {
    Error(null, error);
  }
}
export async function GetParameters() {
  const data = await axios.get(`${URL_BASE}documentos/getparameters`);
  if (data.data.status === 200) {
    //
    return data.data;
  }
  return [];
}

/**
 * Get the UENs loaded into the system
 * @param {boolean} [justActives=true] If true, will return only the active records
 * @returns {Promise<import("types/typedef/catalogue").UenI[]>}
 */
export async function GetUEN(justActives = true) {
  try {
    /**
     * @type {import("axios").AxiosResponse<{status:number,UEN:import("types/typedef/catalogue").UenI[]}>}
     */
    const data = await axios.get(`${URL_BASE}documentos/getuen`, {
      withCredentials: true,
    });

    if (justActives && isValidHttpResCode(data.status)) {
      return data.data.UEN.filter((uen) => uen.status === true);
    }

    return data.data.UEN;
  } catch (error) {
    return [];
  }
}

/**
 * Get the TCP of the enterprise (most recent)
 * @returns {TCP} Current TCP of the enterprise
 */
export async function GetTCP() {
  const data = await axios.get(`${URL_BASE}documentos/gettcp`);
  //
  if (data.status === 200) {
    return data.data.TCP;
  } else {
    return {};
  }
}
export async function GetDocumentProgress(documentType) {
  const data = await axios.get(
    `${URL_BASE}documentos/getdocumentprogress/${documentType}`
  );
  if (data.status === 200) {
    return data.data.progress;
  } else {
    return [];
  }
}

/**
 * Fetch the information of a document
 * @param {number} documentID - ID of the document to fetch
 * @returns {Promise<import("axios").AxiosResponse<import("./documentsTypes").DocumentDataI>>}
 * @deprecated
 */
export async function GetDocumentInfo(documentID) {
  try {
    const data = await axios.get(
      `${URL_BASE}documentos/getdocumentinfo/${documentID}`,
      {
        withCredentials: true,
      }
    );
    return data;
  } catch (error) {
    Error(null, "Hubo un error");
    return false;
  }
}

export async function PostDocumnet(document) {
  try {
    const { data } = await axios.post(
      `${URL_BASE}documentos/documento`,
      document
    );

    if (data.status === 200) {
      return true;
    }

    Error(() => {}, "Hubo un error");
    return false;
  } catch (error) {
    Error(() => {}, error);
    return false;
  }
}

/**
 * Update the information of a document
 * @param {object} document - Info of the document that was edited
 * @param {number} idDocument - ID of the document that was edited
 * @returns {boolean} True if the update query was success
 */
export async function UpdateDocument(document, idDocument) {
  try {
    const { data } = await axios.put(
      `${URL_BASE}documentos/documento/${idDocument}`,
      document
    );

    if (data.status === 200) {
      return true;
    }

    Error(() => {}, "Hubo un error");
    return false;
  } catch (error) {
    Error(() => {}, error);
  }
}

/**
 * Fetch a document
 *
 * @param {number} page - Page requested to fetch the document
 * @param {string} order - ASC or DESC
 * @param {string} columnOrdering - Value to indicate which column must be ordered the data
 * @param {string} aditionalQuery - Aditional querys to fetch the data
 */
export async function GetDocuments(
  page,
  order,
  columnOrdering,
  aditionalQuery
) {
  try {
    let { data } = await axios.get(
      `${URL_BASE}documentos?pagina=${page}&orden=${order}&columna=${columnOrdering}${aditionalQuery}`
    );

    const documents = parseDocuments(data.data.documents);

    data.data.documents = documents;

    if (data.status === 200) return data;

    Error(() => {}, "Hubo un error");
    return false;
  } catch (error) {
    console.error(error);

    Error(() => {}, error);
    return false;
  }
}

export function parseDocuments(documents) {
  const newDocuments = documents.map((document) => {
    document = {
      ...document,
      // totalAmount: mnCurrency(document.totalAmount),
    };
    return document;
  });

  return newDocuments;
}

/**
 * Build the query string for the filter of documents
 *
 * @returns {string} Query string to fetch some data
 */
export function GetFilterSearch() {
  const executive = document.getElementById("executive");
  const typeDocument = document.getElementById("typeDocument");
  const status = document.getElementById("status");
  const beginRange = document.getElementById("filterStartRange");
  const endRange = document.getElementById("filterEndRange");
  const documentNumber = document.getElementById("searchDocument");

  const idCustomer = getIdCustomer();

  let queryString = "";

  if (executive === null) return "";
  queryString += `&ejecutivo=${executive.value}`;

  if (typeDocument === null) return "";
  queryString += `&tipoDocumento=${typeDocument.value}`;

  if (status === null) return "";
  queryString += `&estatus=${status.value}`;

  if (beginRange === null) return "";
  queryString += `&desde=${beginRange.value}`;

  if (endRange === null) return "";
  queryString += `&hasta=${endRange.value}`;

  if (documentNumber === null) return;

  let valSearchDoc = +documentNumber.value;

  if (
    valSearchDoc.length === 0 ||
    typeof valSearchDoc !== "number" ||
    isNaN(valSearchDoc) ||
    valSearchDoc === 0
  ) {
    queryString += `&noDocumento=null`;
  } else {
    queryString += `&noDocumento=${valSearchDoc}`;
  }

  // console.log('Document number value',documentNumber.value);

  // documentNumber.value ==

  // queryString += `&noDocumento=`

  queryString += `&cliente=${idCustomer}`;

  console.log(queryString);

  return queryString;
}

function getIdCustomer() {
  // URL of the browser
  const url = window.location.href;

  // Split all the /
  const params = url.split("/");

  // Last element it's the number of customer
  return +params[params.length - 1];
}

/**
 * Fetch the documents of a customer/provider
 * @param {number} page - Page requested to fetch the document
 * @param {string} order - ASC or DESC
 * @param {string} columnOrdering - Value to indicate which column must be ordered the data
 * @returns {object} Documents obtained
 */
export async function fetchDocuments(
  page,
  order,
  columnOrdering,
  aditionalQuery = ""
) {
  const queryString = GetFilterSearch();

  if (queryString === "") return;

  const documents = await GetDocuments(
    page,
    order,
    columnOrdering,
    queryString
  );

  return documents.data;
}

/**
 * Fetch the information of the documents requested
 * @param {number} page - Page requested from the information
 * @param {number} idExecutive - Id of the executive
 * @param {number} idTypeDocument - Id of the type document to look for
 * @param {number|null} idStatus - Id of the status to look for
 * @param {string} beginDate - In format yyyy-mm-dd
 * @param {string} endDate - In format yyyy-mm-dd
 * @param {string|null|number} noDocument - Text typed on search bar
 * @param {number} idCustomer - Id of the customer
 * @param {number} [idUen=2] Id of the uen
 *
 * @returns {Promise<import("../../types/typedef/documents").DocumentResponse>} Information of the documents searched
 */
export async function fetchDocumentsV2(
  page,
  idExecutive,
  idTypeDocument,
  idStatus,
  beginDate,
  endDate,
  noDocument,
  idCustomer,
  idUen = 2
) {
  try {
    const statusParsed = idStatus === null ? "-1" : idStatus;

    /**
     * @type {import("axios").AxiosResponse<import("../../types/typedef/documents").DocumentResponse>}
     */
    let res = await axios.get(
      `${URL_BASE}documentos?pagina=${page}&orden=DESC&columna=idDocument&ejecutivo=${idExecutive}&tipoDocumento=${idTypeDocument}&estatus=${statusParsed}&desde=${beginDate}&hasta=${endDate}&noDocumento=${noDocument}&cliente=${idCustomer}`
    );

    return res.data;
  } catch (error) {
    Error(() => {}, "No se pudieron obtener los documentos");
    return [];
  }
}

/**
 * Fetch the comments of an specific document
 * @param {number} idDocument - ID of the document to fetch
 * @returns {Comments[]} Comments of the document
 */
export async function fetchComments(idDocument) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}documentos/documento/${idDocument}/comentarios`
    );

    return data.comments;
  } catch (error) {
    Error(undefined, error);
  }
}

/**
 * Fetch the document items of an specific document
 * @param {number} idDocument - ID of the document to fetch
 * @returns {apiDocItems[]} Document items of the document
 */
export async function fetchDocItems(idDocument) {
  if (idDocument === null) {
    return [];
  }

  try {
    const { data } = await axios.get(
      `${URL_BASE}documentos/documento/${idDocument}/partidas`
    );

    return data.documentItem;
  } catch (error) {
    Error(undefined, error);
  }
}

/**
 * Update the unit cost on the catalogue
 * @param {object} info - Information in order to update the unit cost
 */
export async function UpdateUnitCost(info) {
  try {
    const { data } = await axios.put(
      `${URL_BASE}documentos/catalogo/costo`,
      info
    );

    if (data.status === 200) {
      // Success(()=>{},data.message);
      return;
    }

    console.error(data);
  } catch (error) {
    Error(() => {}, error);
  }
}

export async function UpdateUnitPrice(info) {
  try {
    const { data } = await axios.put(
      `${URL_BASE}documentos/catalogo/precio`,
      info
    );

    if (data.status === 200) {
      // Success(()=>{},data.message);
      return;
    }

    console.error(data);
  } catch (error) {
    Error(() => {}, error);
  }
}

/**
 * Get the actual TCP of the system
 * @returns {Promise<import("./typesDocuments").GetTcpValues>}
 */
export async function GetTcp() {
  try {
    let tcp = await fetch(`${URL_BASE}documentos/tcp`, {
      credentials: "include",
    });

    tcp = await tcp.json();

    return tcp.tcp;
  } catch (error) {
    return {
      DOF: 0,
      id: null,
      saiko: 0,
      sales: 0,
    };
  }
}

/**
 * Get the information of progress with the id
 *
 * @param {number} id - ID of the progress
 * @returns {object}
 */
export async function GetProgress(id) {
  try {
    let apiProgress = await fetch(`${URL_BASE}documentos/porcentaje/${id}`);

    apiProgress = await apiProgress.json();

    return apiProgress.progress;
  } catch (error) {}
}

/**
 * Get the list of the CFDI existing on the system
 */
export async function GetCFDI() {
  try {
    let cfdi = await fetch(`${URL_BASE}documentos/cfdi`);

    cfdi = await cfdi.json();

    return cfdi.cfdi;
  } catch (error) {
    Error(undefined, error);
  }
}

/**
 * Get the probability description
 *
 * @param {number} id - ID of the probability
 * @returns {object} Probability description
 */
export async function getProbability(id) {
  try {
    let apiProbability = await fetch(
      `${URL_BASE}documentos/probabilidad/${id}`
    );

    apiProbability = await apiProbability.json();

    return apiProbability.probability;
  } catch (error) {
    console.log(error);

    return "Error";
  }
}

export async function loadPdfData(documentId = 99) {
  // Este es el boton que se necesita para exportar el pdf
  try {
    const { data } = await axios.get(
      `${URL_BASE}documentos/pdf-data/${documentId}`
    );

    console.log(data);

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function senEmailPDF(document64, emailInfo) {
  try {
    const { data } = await axios.post(`${URL_BASE}documentos/send-document`, {
      document64,
      emailInfo,
    });

    if (data.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Erro en la conexión: ", error);
    return false;
  }
}

// Pendinete
export async function fetchReminders(
  page,
  order,
  column,
  beginDate,
  endDate,
  aditionalQuery = ""
) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}documentos/reminders?pagina=${page}&orden=${order}&columna=${column}&fechaInicio=${beginDate}&fechaFin=${endDate}${aditionalQuery}`
    );
  } catch (error) {}
}

export async function fetchReminderHeader(idDocument) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}documentos/reminders/header/${idDocument}`
    );
    if (data.status === 200) {
      return data.reminderHeader;
    }
  } catch (error) {
    return null;
  }
}

/**
 * Notify the supervisor to check the invoice revision
 * @param {NewReminder} reminderData - Information of the reminder
 * @param {string} mailTo - Person will receive the email to review the invoice
 * @returns {Boolean}
 */
export async function notifyRevisionInvoice(revisionInfo, mailTo) {
  try {
    await axios.post(`${URL_BASE}documentos/notificar-revision`, {
      ...revisionInfo,
      ...mailTo,
    });

    return true;
  } catch (error) {
    const { data } = error.response;

    Error(() => {}, `Error: ${data.codeError}. ${data.message}.`);

    throw {
      codeError: "MJ",
      message: "No se pudo mandar la revision de la prefactura",
      errorDetails: error,
    };
  }
}

/**
 * Add a new reminder to the system
 *
 * @param {NewReminder} reminderData - Information of the reminder
 * @returns {object} Info of the query execution
 */
export async function addReminder(reminderData) {
  try {
    const { data } = await axios.post(
      `${URL_BASE}documentos/reminders/addreminder`,
      reminderData
    );

    if (data.status === 200) {
      return {
        previousCommentId: data.previousCommentId,
        status: true,
        mensaje: "Se agrego el recordatorio correctamente",
      };
    } else {
      return {
        status: false,
        mensaje: "No se pudo agregar el recordatorio, intente de nuevo",
      };
    }
  } catch (error) {
    return {
      status: false,
      mensaje: "No se pudo agregar el recordatorio, intente de nuevo",
    };
  }
}
export async function addCommentTag(reminderData) {
  try {
    const { data } = await axios.post(
      `${URL_BASE}documentos/reminders/tagcomment`,
      reminderData
    );
    if (data.status === 200) {
      return {
        status: true,
        mensaje: "Se agrego el recordatorio correctamente",
      };
    } else {
      return {
        status: false,
        mensaje: "No se pudo agregar el recordatorio, intente de nuevo",
      };
    }
  } catch (error) {
    return {
      status: false,
      mensaje: "No se pudo agregar el recordatorio, intente de nuevo",
    };
  }
}

export async function getReminders(
  page,
  order,
  columnordering,
  additionalQuery
) {
  try {
    console.log("aditional query");
    console.log(additionalQuery);
    let executiveID;
    let documentID;
    if (!!additionalQuery) {
      executiveID = additionalQuery[0];
      documentID = additionalQuery[1];
      console.log(executiveID, documentID);
    }
    // const {data} = await axios.get(`${URL_BASE}documentos/reminders/get-reminder?pagina=${page}&orden=${order}&columna=${columnordering}&executiveID=${executiveID}&documentID=${documentID}`);
    const { data } = await axios.get(
      `${URL_BASE}documentos/reminders/get-reminder?pagina=${page}&orden=${order}&columna=${columnordering}${additionalQuery}`
    );

    if (data.status === 200) {
      data.data = {
        ...data.data,
        status: true,
      };
      return data.data;
    } else {
      return {
        status: false,
      };
    }
  } catch (error) {
    return {
      status: false,
    };
  }
}
export async function getReminderTags(params) {
  try {
    const data = await axios.get(
      `${URL_BASE}documentos/reminders/getremindertype/${params.executiveID}/${params.reminderFrom}`
    );
    if (data.status === 200) {
      return data.data;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

/**
 * Cancel a quote
 * @param {import("./typesDocuments").CancelQuote} cancelInfo - Information to cancel a quote
 * @returns {Promise<boolean>}
 */
export async function cancelDocument(cancelInfo) {
  try {
    const data = await axios.put(
      `${URL_BASE}documentos/documento/cancelar`,
      cancelInfo
    );

    const doctype = {
      1: "Cotizacion",
      2: "Pedido",
      3: "Orden de compra",
      6: "Contrato",
    };

    if (data.status === 200) {
      Success(() => {},
      `${doctype[cancelInfo.docuemntType]} ${cancelInfo.docuemntType === 6 ? "terminado" : "cancelado"}`);
      return true;
    } else {
      Error(() => {},
      "No se pudo cancelar la cotizacion, favor de reportar a soporte. Codigo de error [8d3d0883-124a-48bf-9b27-c0a4412b79a1]");
      return false;
    }
  } catch (error) {
    Error(() => {},
    "No se pudo cancelar la cotizacion, favor de reportar a soporte. Codigo de error [8d3d0883-124a-48bf-9b27-c0a4412b79a1]");
    return false;
  }
}

export async function getOcStatus(documentId) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}documentos/documento/ocstatus/${documentId}`
    );
    // console.log(data);
    if (data.status === 200) {
      return {
        status: true,
        info: data.data[0],
      };
    } else {
      return {
        status: false,
        ErrorMessage: "Fallo la conexion",
      };
    }
  } catch (error) {
    return {
      status: false,
      ErrorMessage: error,
    };
  }
}
export function numberToCurrencyTextFormat(number, currency) {
  // const n2words = require("n2words/dist/n2words.js");
  let newTotal = number.replace("$", "").split(".");
  const entero = parseInt(newTotal[0].replace(",", ""), 10);
  const fraccion = newTotal[1];
  const currencyText = currency === "MXN" ? "PESOS" : "DOLARES";
  const enteroToText = n2words(entero, { lang: "es" }).toUpperCase();
  const mensaje = `${enteroToText} &nbsp ${fraccion}/100  &nbsp ${currencyText}`;
  return mensaje;
}

/**
 * @param {string} invoiceId - Facturama invoice id
 * @param {number} preinvoiceId - Id of the preinvoice to be cancelled
 * @example 
  cancelingInvoice('gU2WjoCgcPwHopaKdbotJA2',2496)
  .then(()=>alert('Cancelled!'))
  .catch(e=>console.log(do something to handle it)))
 */
export async function cancelingInvoice(invoiceId, preinvoiceId) {
  // alert(preinvoiceId);

  try {
    const data = await axios.delete(
      `${URL_BASE}documentos/cancel/invoice/${invoiceId}/${preinvoiceId}`,
      {
        withCredentials: true,
      }
    );

    Success(() => {}, "Factura cancelada [en desarrollo]");
  } catch (error) {
    const { request, response } = error;

    Error(() => {}, response.data.message);
  }
}

/**
 * Update the invoice
 * @param {import("types/typedef/documents").DocumentI} document - Information of the document updated
 * @param {import("types/typedef/customHooks/useComments").CommentsI} comments - Information of the comments
 * @param {{
 *  id:number,
 *  fullName:string
 * }} executive - Info of the executive
 * @returns {boolean} True if invoice was updated successfully
 */
export async function updateContract(document, comments, executive, items) {
  try {
    await axios.put(`${URL_BASE}documentos/contrato`, {
      document,
      comments,
      executive,
      items,
    });

    return true;
  } catch (error) {
    const { response } = error;
    const { data, status, statusText } = response;

    Error(() => {}, `Error: ${data.errorCode}. ${data.message}`);
    return false;
  }
}
/**
 * Update the invoice
 * @param {import("types/typedef/documents").DocumentI} document - Information of the document updated
 * @param {import("types/typedef/customHooks/useComments").CommentsI} comments - Information of the comments
 * @param {{
 *  id:number,
 *  fullName:string
 * }} executive - Info of the executive
 * @returns {boolean} True if invoice was updated successfully
 */
export async function updateOc(document, comments, executive) {
  try {
    await axios.put(`${URL_BASE}documentos/oc`, {
      document,
      comments,
      executive,
    });

    return true;
  } catch (error) {
    const { response } = error;
    const { data, status, statusText } = response;

    Error(() => {}, `Error: ${data.errorCode}. ${data.message}`);
    return false;
  }
}

/**
 * Get the OC that handle a provider
 * @param {string} rfc - RFC of the provider
 * @param {number} idProvider - Id of the provider with the rfc selected
 * @returns {import("../../types/typedef/documents").OcsProvider[]} Info of the ocs
 */
export async function getAllOcDocumentProvider(rfc, idProvider) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}documentos/oc?rfc=${rfc}&proveedor=${idProvider}`
    );

    const parsedInfo = data.map((item) => ({
      ...item,
      isValidImport: true,
      isValidResidueOdc: true,
      import: {
        number: 0,
        text: "$0.00",
      },
      tc: {
        number: 1,
        text: "$1.00",
      },
      applied: {
        number: 0,
        text: "$0.00",
      },
      newResidue: {
        number: 0,
        text: "$0.00",
      },
    }));

    return parsedInfo;
  } catch (e) {
    console.log(e);
    Error(() => {}, "Error msg en desarrollo");
    return [];
  }
}
/**
 *
 * @param {number} documentId - The document id
 * @param {boolean} fromLegal - Indicates if the releted documents come from legal documents
 * @returns {Promise<import("axios").AxiosResponse<import("types/typedef/customHooks/useDocPopUp").ResDocumentsRelatedPopUp>>} -Documents related
 */
export async function PopUpDocumentsRelated(documentId, fromLegal = false) {
  const ERROR_DATA = {
    data: {
      fe: null,
      fr: null,
    },
  };

  try {
    if (typeof documentId !== "number") return ERROR_DATA;

    /**
     * @type {import("axios").AxiosResponse<import("types/typedef/customHooks/useDocPopUp").ResDocumentsRelatedPopUp>}
     */
    const data = await axios.get(
      `${URL_BASE}documentos/documento/related/${documentId}/${fromLegal}`
    );

    return data;
  } catch (error) {
    return ERROR_DATA;
  }
}

/**
 * Get the comments of a document
 * @param {number} id - Id of the document to fetch
 * @returns {Promise<import("types/typedef/customHooks/useComments").CommentI[]>}
 */
export async function getDocumentComments(id) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}documentos/obtener/comentarios/${id}`,
      {
        withCredentials: true,
      }
    );

    return data;
  } catch (e) {}
}

/**
 * Parse the comments for the document component
 * @param {import("types/typedef/customHooks/useComments").CommentI[]} comments
 */
export function parseCommentsApi(comments = []) {
  return comments.map((comment) => ({
    ...comment,
    content: comment.comment,
    isEditing: false,
    cc: [],
    idTypeComment: comment.commentType,
  }));
}

/**
 * Get the items of a document
 * @param {number} idDocument - Id of the document to fetch the documents
 * @returns {import("types/typedef/catalogue").ItemI[]}
 */
export async function getDocumentItemsV3(idDocument) {
  // try {
  //   const data = await getDocumentData(idDocument);

  //   const items = data.data.items.map(item=>{

  //     const pu = item.currency.code === "MXN" ? item.mxn.pu.number : item.usd.pu.number;
  //     const puDiscount =  item.currency.code === "MXN" ? item.mxn.pu.discount.number : item.usd.pu.discount.number
  //     const puTotalIva = item.currency.code === "MXN" ? item.mxn.pu.totalIva.number : item.usd.pu.totalIva.number;
  //     const puImport = item.currency.code === "MXN" ? item.mxn.pu.total.number : item.usd.pu.total.number;
  //     const puUnitary = item.currency.code === "MXN" ? item.mxn.pu.realUnit.number : item.usd.pu.realUnit.number;

  //     const cu = item.currency.code === "MXN" ? item.mxn.cu.number : item.usd.cu.number;
  //     const cuDiscount = item.currency.code === "MXN" ? item.mxn.cu.discount.number : item.usd.cu.discount.number;
  //     const cuTotalIva = item.currency.code === "MXN" ? item.mxn.cu.totalIva.number : item.usd.cu.totalIva.number;
  //     const cuImport = item.currency.code === "MXN" ? item.mxn.cu.total.number : item.usd.cu.total.number;
  //     const cuUnitary = item.currency.code === "MXN" ? item.mxn.cu.realUnit.number : item.usd.cu.realUnit.number;

  //     const price = {
  //       unitary:puUnitary + truncateDecimals(puDiscount/item.quantity,2), // FIXME: Pendiente con adrian
  //       sell:puUnitary,
  //       iva:puTotalIva,
  //       import:puImport,
  //       subtotal:truncateDecimals(puTotalIva+puImport,2),
  //       discount:truncateDecimals(puDiscount/item.quantity,2) // FIXME: Descuento en terminos de dinero por unitario
  //     }

  //     const cost = {
  //       unitary:cuUnitary, // FIXME: Pendiente con adrian
  //       sell:cuUnitary - truncateDecimals(cuDiscount/item.quantity,2),
  //       iva:cuTotalIva,
  //       import:cuImport,
  //       subtotal:truncateDecimals(cuTotalIva+cuImport,2),
  //       discount:truncateDecimals(cuDiscount/item.quantity,2) // FIXME: Descuento en terminos de dinero por unitario
  //     }

  //     const toUse = {
  //       uuid: window.crypto.randomUUID(),
  //       id:item.id,
  //       description:item.description,
  //       satUmDescription:item.satUmDescription,
  //       satCodeDescription:item.satCodeDescription,
  //       pu:{
  //         number:pu,
  //         text:mnCurrency(pu),
  //         discount:truncateDecimals(puDiscount/item.quantity,2)
  //       },
  //       cu:{
  //         number:cu,
  //         text:mnCurrency(cu),
  //         discount:truncateDecimals(cuDiscount/item.quantity,2)
  //       },
  //       satCode:item.satCode,
  //       satUm:item.satUm,
  //       iva:{
  //         number:item.iva.number,
  //         text:item.iva.text,
  //         exempt:!!item.iva.exempt
  //       },
  //       sku:item.sku,
  //       uen:{
  //         id:item.uen.id,
  //         description:item.uen.description,
  //         family:item.uen.family,
  //         marginRate:item.uen.marginRate
  //       },
  //       catalogue:{
  //         id:item.catalogue.id,
  //         description:item.catalogue.description
  //       },
  //       currency:{
  //         code:item.currency.code ,
  //         symbol:"$",
  //         description:""
  //       },
  //       quantity:item.quantity,
  //       discount:item.clientDiscoount.number,
  //       calculations:{
  //         price,
  //         cost
  //       },
  //       utility:510
  //     }

  //     return toUse;
  //   })

  //   console.log('refactor',items)

  //   return items;

  // } catch (error) {
  //   return [];
  // }

  try {
    const { data } = await axios.get(`${URL_BASE}partidas/${idDocument}`, {
      withCredentials: true,
    });

    const dataToUse = data.map((item) => ({
      ...item,
      uuid: window.crypto.randomUUID(),
    }));

    console.log(dataToUse, "here");

    return dataToUse;
  } catch (e) {}
}

/**
 * Get the comments with copy
 * @param {number} idDocument - Id of the document that has the copies
 * @param {1|2|3|6} idTypeDocument - Id of the type of document that want's to
 * @returns {object[]} Comments
 */
export async function getCommentsWithCc(idDocument, idTypeDocument) {
  // try {
  //   const { data } = await getDocumentData(idDocument);

  //   const items = data.items.map(item=>{

  //     const pu = item.currency.code === "MXN" ? item.mxn.pu.number : item.usd.pu.number;
  //     const puDiscount =  item.currency.code === "MXN" ? item.mxn.pu.discount.number : item.usd.pu.discount.number
  //     const puTotalIva = item.currency.code === "MXN" ? item.mxn.pu.totalIva.number : item.usd.pu.totalIva.number;
  //     const puImport = item.currency.code === "MXN" ? item.mxn.pu.total.number : item.usd.pu.total.number;
  //     const puUnitary = item.currency.code === "MXN" ? item.mxn.pu.realUnit.number : item.usd.pu.realUnit.number;

  //     const cu = item.currency.code === "MXN" ? item.mxn.cu.number : item.usd.cu.number;
  //     const cuDiscount = item.currency.code === "MXN" ? item.mxn.cu.discount.number : item.usd.cu.discount.number;
  //     const cuTotalIva = item.currency.code === "MXN" ? item.mxn.cu.totalIva.number : item.usd.cu.totalIva.number;
  //     const cuImport = item.currency.code === "MXN" ? item.mxn.cu.total.number : item.usd.cu.total.number;
  //     const cuUnitary = item.currency.code === "MXN" ? item.mxn.cu.realUnit.number : item.usd.cu.realUnit.number;

  //     const price = {
  //       unitary:puUnitary, // FIXME: Pendiente con adrian
  //       sell:null,
  //       iva:puTotalIva,
  //       import:puImport,
  //       subtotal:truncateDecimals(puTotalIva+puImport,2),
  //       discount:puDiscount // FIXME: Descuento en terminos de dinero por unitario
  //     }

  //     const cost = {
  //       unitary:cuUnitary, // FIXME: Pendiente con adrian
  //       sell:null,
  //       iva:cuTotalIva,
  //       import:cuImport,
  //       subtotal:truncateDecimals(cuTotalIva+cuImport,2),
  //       discount:cuDiscount // FIXME: Descuento en terminos de dinero por unitario
  //     }

  //     const toUse = {
  //       id:item.id,
  //       description:item.description,
  //       satUmDescription:item.satUmDescription,
  //       satCodeDescription:item.satCodeDescription,
  //       pu:{
  //         number:pu,
  //         text:mnCurrency(pu),
  //         discount:puDiscount
  //       },
  //       cu:{
  //         number:cu,
  //         text:mnCurrency(cu),
  //         discount:cuDiscount
  //       },
  //       satCode:item.satCode,
  //       satUm:item.satUm,
  //       iva:{
  //         number:item.iva.number,
  //         text:item.iva.text,
  //         exempt:!!item.iva.exempt
  //       },
  //       sku:item.sku,
  //       uen:{
  //         id:item.uen.id,
  //         description:item.uen.description,
  //         family:item.uen.family,
  //         marginRate:item.uen.marginRate
  //       },
  //       catalogue:{
  //         id:item.catalogue.id,
  //         description:item.catalogue.description
  //       },
  //       currency:{
  //         code:item.currency,
  //         symbol:"$",
  //         description:""
  //       },
  //       quantity:item.quantity,
  //       discount:item.clientDiscoount.number,
  //       calculations:{
  //         price,
  //         cost
  //       },
  //       utility:0
  //     }

  //     return toUse;
  //   })

  //   return items;

  // } catch (error) {
  //   return [];
  // }

  // return;

  try {
    const { data } = await axios.get(
      `${URL_BASE}documentos/obtener/comentarios/copia/${idDocument}/${idTypeDocument}`,
      {
        withCredentials: true,
      }
    );

    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

/**
 * Get the pdf file in order to download it for the user
 * @param {number} idDocument - Id of the document to fetch his pdf
 * @param {boolean} showRelatedDocuments - Indicates if related documents must be appear
 * @returns {import("./typesDocuments").PdfDocumentI}
 */
export async function getDocumentPdf(idDocument, showRelatedDocuments = true) {
  try {
    const { docNumber, type } = await GetDocument(idDocument);

    const { data } = await axios.get(
      `${URL_BASE}documentos/obtener/pdf/${idDocument}/${!showRelatedDocuments}`,
      {
        withCredentials: true,
      }
    );

    const pdf = await base64ToFile(
      "application/pdf",
      data,
      `${type.description}-${docNumber}.pdf`,
      false
    );

    return {
      pdf,
      wasDownloaded: true,
    };
  } catch (error) {
    return {
      pdf: null,
      wasDownloaded: false,
    };
  }
}

/**
 * Get the pdf file in order to download it for the user
 * @param {number} idDocument - Id of the document to fetch his pdf
 * @param {boolean} showRelatedDocuments - Indicates if related documents must be appear
 * @returns {FileList} File list of the pdf downloaded from the server
 */
export async function downloadPdfDocument(
  idDocument,
  showRelatedDocuments = true
) {
  try {
    const { pdf } = await getDocumentPdf(idDocument, showRelatedDocuments);

    const fileList = fileArrayToFileList([pdf]);

    return fileList;
  } catch (error) {
    Error(() => {}, "No se pudo descargar el pdf del documento");
    return;
  }
}

/**
 * Update the status of an odc to sended
 * @param {number} odc - Odc id
 */
export async function updateOdcStatusToSended(odc = null) {
  if (odc === null) {
    Error(() => {}, "No se pudo actualizar el estatus de la ODC");
    return;
  }

  try {
    await axios.put(
      `${URL_BASE}documentos/actualizar/estatus/odc/${odc}`,
      {},
      {
        withCredentials: true,
      }
    );
  } catch (error) {
    Error(() => {}, "No se pudo actualizar el estatus de la ODC");
  }
}

/**
 * Download the pdf and xml generated by facturama
 * @param {number} idDocument - Id of the document
 * @returns {FileList} Files
 */
export async function downloadPdfAndXmlInvoice(idDocument) {
  try {
  } catch (error) {
    Error(() => {}, "No se pudo descargar el pdf y xml del SAT");
    return;
  }
}

export const INVOICE_STATUS = {
  pedidos: 2,
  facturasEmitidas: 10,
};

/**
 * Get the list of status that must be fetched
 * @param {import("components/general/Forms/Selects/StatusInvoice/types").FROM_INVOICE} from - From where came the fetch of the status
 * @returns {Promise<import("components/general/Forms/Selects/StatusInvoice/types").StatusInvoice[]>} List of status
 */
export async function getInvoiceStatus(from = "pedidos") {
  try {
    if (from === "pedidos") {
      const pedidos = await axios.get(
        `${URL_BASE}documentos/estados-documentos/2`,
        {
          withCredentials: true,
        }
      );
      return pedidos.data.statusDocuments;
    }

    const facturasEmitidas = await axios.get(
      `${URL_BASE}documentos/estados-documentos/10`,
      {
        withCredentials: true,
      }
    );

    const CxcParcial = {
      state: 20,
      description: "CxC/Parcial",
      order: 1,
      logicalErase: 1,
    };

    return [...facturasEmitidas.data.statusDocuments, CxcParcial];
  } catch (error) {
    return [];
  }
}

/**
 * Download the pdf file of a document
 * @param {number} idDocument - Id document
 * @param {"intranet"|"extranet"} type - Type of document to download
 * @returns {FileList} PDF file downloaded from the server as FileList
 * @example
 * (async function(){
 *  const fileList = await downloadPdfDocumentV2(23,"extranet")
 * })()
 */
export async function downloadPdfDocumentV2(
  idDocument,
  type = "intranet" | "extranet"
) {
  try {
    const { data } = await axios.get(
      `${URL_BASE}documentos/pdf?tipo=${type}&idDocumento=${idDocument}`,
      {
        withCredentials: true,
      }
    );

    const file = await base64ToFile(
      "application/pdf",
      data.base64,
      data.fileName,
      false
    );

    return fileArrayToFileList([file]);
  } catch (error) {
    return;
  }
}
/**
 * Generate a file list that can be attached into a file input
 * @param {number} idDocument - Id document
 * @param {string} fileName - Filename without the extension
 * @returns {Promise<FileList>} PDF file downloaded from the server as FileList
 * @example
 * (async function(){
 *  const fileList = await downloadPdfDocumentV3(23,"Cotizacion-0000044")
 * })()
 */
export async function downloadPdfDocumentV3(idDocument, fileName = "Document") {
  try {
    // const pdf = await downloadFileFromBlobV3(idDocument);

    // const pdfFile = new File([pdf],`${fileName}.pdf`,{type:'application/pdf'});

    // return fileArrayToFileList([pdfFile]);

    const res = await fetch(
      `${URL_BASE}documentos/download/pdf/${idDocument}`,
      {
        credentials: "include",
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      const pdfDocument = await res.blob();
      const pdfFileInstance = new File([pdfDocument], `${fileName}.pdf`, {
        type: "application/pdf",
      });
      return fileArrayToFileList([pdfFileInstance]);
    }

    return fileArrayToFileList([]);
  } catch (error) {
    Error(() => {}, "No se pudo adjuntar el pdf del documento");

    return fileArrayToFileList([]);
  }
}

/**
 * Download the file of the sat that has the invoice
 * @param {number} idDocument - Id of the document
 * @param {"pdf"|"xml"} type - Type of file to download, xml or pdf
 * @returns {Promise<File>} File
 */
export async function downloadSatInvoiceFiles(idDocument, type = "pdf") {
  try {
    const res = await fetch(
      `${URL_BASE}documentos/obtener/archvio/legal?idDocument=${idDocument}&type=${type}`,
      {
        credentials: "include",
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      const data = await res.blob();
      const fileParsed = new File([data], `Factura.${type}`);
      return fileParsed;
    }

    Error(() => {},
    `No se pudo descargar ${type} de la factura, favor de reportar a soporte`);
    return null;
  } catch (error) {
    Error(() => {},
    `No se pudo descargar ${type} de la factura, favor de reportar a soporte`);
    return null;
  }
}

/**
 * Get the attach files to use when trying to send an invoice
 * @param {number} idInvoice - ID of the invoice
 * @param {string?} folio - Folio of the invoice
 * @returns {Promise<FileList>}
 */
export async function attachInvoiceFilesEmail(idInvoice, folio = "") {
  try {
    const [pedido, pdfSat, xmlSat] = await Promise.all([
      downloadPdfDocumentV3(idInvoice, `Pedido ${folio}`),
      downloadSatInvoiceFiles(idInvoice, "pdf"),
      downloadSatInvoiceFiles(idInvoice, "xml"),
    ]);

    const fileList = fileArrayToFileList([pedido["0"], pdfSat, xmlSat]);

    return fileList;
  } catch (error) {
    const pdf = await downloadPdfDocumentV2(idInvoice, "extranet");
    return pdf;
  }
}
/**
 * Get the attach files to use when trying to send an invoice
 * @param {number} idInvoice - ID of the invoice
 * @returns {Promise<FileList>}
 */
export async function attachInvoiceFilesEmailV2(
  idInvoice,
  fileName = "Document"
) {
  try {
    const [data, pdfSat, xmlSat] = await Promise.all([
      await axios.get(`${URL_BASE}documentos/download/pdf/${idInvoice}`, {
        withCredentials: true,
        responseType: "blob",
      }),
      downloadSatInvoiceFiles(idInvoice, "pdf"),
      downloadSatInvoiceFiles(idInvoice, "xml"),
    ]);

    const invoicePdf = new File([data.data], "Pedido.pdf");

    const fileList = fileArrayToFileList([invoicePdf, pdfSat, xmlSat]);

    return fileList;
  } catch (error) {
    const pdf = await downloadPdfDocumentV3(idInvoice, fileName);
    return pdf;
  }
}

/**
 * Get the list of CFDI
 * @returns {object[]}
 */
export async function getCfdiUse() {
  try {
    const { data } = await axios.get(`${URL_BASE}facturacion/lista/cfdi`, {
      withCredentials: true,
    });

    return data;
  } catch (error) {
    return [];
  }
}

/**
 * Information of an invoice with his uuid
 * @param {string} uuid - Get the information of the invoice with his uuid
 * @returns {Promise<import("../../../../server/models/documentsV2Types").InvoiceByUUID>}
 */
export async function getInvoiceByUuid(uuid) {
  try {
    const response = await fetch(`${URL_BASE}documentos/factura/${uuid}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();

    if (response.ok && isValidHttpResCode(response.status)) {
      return data;
    }

    Error(() => {}, data.message);

    return undefined;
  } catch (error) {
    return undefined;
  }
}

export async function getContracts({
  page,
  order,
  column,
  executive,
  status,
  from,
  to,
  noContract,
  customer,
}) {
  try {
  } catch (error) {
    DisplayError(error);
    return [];
  }
}
/**
 *
 * @returns {{status:number,data:import("../../../../types/documentActions").Concepts[]}}
 */
export const getDocumentsIncomeConcepts = async () => {
  try {
    const concepts = await axios.get(`${URL_BASE}documentos/conceptos/ventas`, {
      withCredentials: true,
    });
    return concepts;
  } catch (error) {
    console.log("Fallo el api de conceptos");
    console.log(error);
    return [];
  }
};
/**
 *
 * @returns {{status:number,data:import("../../../../types/documentActions").Concepts[]}}
 */
export const getDocumentsExpensesConcepts = async () => {
  try {
    const concepts = await axios.get(`${URL_BASE}documentos/conceptos/costos`, {
      withCredentials: true,
    });
    return concepts;
  } catch (error) {
    console.log("Fallo el api de conceptos");
    console.log(error);
    return [];
  }
};

export async function getOdcPdf(idDocument) {
  try {
    const res = await fetch(
      `${URL_BASE}documentos/download/odc/pdf/${idDocument}`,
      {
        credentials: "include",
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      const data = await res.blob();

      return data;
    }
    Error(() => {},
    `No se pudo descargar el pdf de odc, favor de reportar a soporte`);
    return null;
  } catch (error) {
    Error(() => {}, error.message);
    return null;
  }
}

/**
 * Cancelation quote motives
 * @returns {Promise<import("../../../../types/documents").QuoteCancelationMotive[]>}
 */
export async function getQuoteCancelationMotives() {
  try {
    const res = await fetch(
      `${URL_BASE}documentos/cotizacion/motivos-cancelacion`,
      {
        credentials: "include",
      }
    );

    const data = await res.json();
    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }
    Error(() => {}, data.message);
    return [];
  } catch (error) {
    Error(() => {}, error.message);
    return [];
  }
}

/**
 * Get the information about the documents worked on the system on an excel file
 * @returns {Promise<Blob|undefined>}
 */
export async function getDocumentsLogs() {
  try {
    const res = await fetch(`${URL_BASE}especiales/logs/uso`, {
      credentials: "include",
      method: "GET",
    });

    const data = await res.blob();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
}

/**
 * Get the pending invoices with a current residue more than $0.00
 * @returns {Promise<import("../../../../types/NC/nce").InvoiceComboI[]>}
 */
export async function getPendingInvoices() {
  try {
    const res = await fetch(`${URL_BASE}documentos/facturas-pendientes`, {
      credentials: "include",
      method: "GET",
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return [];
  } catch (error) {
    Error(() => {}, error.message);
    return [];
  }
}

/**
 * Get the quotes with status `Abierta`
 * @param {number} idExecutive - Id of the user logged into praxia
 * @param {number} page - Page requested for the information
 * @returns {Promise<import("../../../../server/models/documentsV2Types").DtoPendingQuotes>}
 */
export async function getPendingQuotes(idExecutive, page) {
  try {
    const res = await fetch(
      `${URL_BASE}documentos/cotizacion/abiertas?idExecutive=${idExecutive}&page=${page}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return [];
  } catch (error) {
    Error(() => {}, error.message);
    return [];
  }
}

/**
 * Get a report of the CxP on the system
 * @param {import("./typesDocuments").CxpCxcReport} param - Params to get the information of the report
 * @returns {Promise<import("../../../../server/models/documentsV2Types").CxpReport>}
 */
export async function getCxpReport({
  endDate,
  startDate,
  socialReason = null,
}) {
  try {
    const startDateQuery = startDate !== null ? `&startDate=${startDate}` : "";
    const endDateQuery = endDate !== null ? `&endDate=${endDate}` : "";
    const type = `?type=cxp`;
    const socialReasonQuery =
      socialReason === null ? "" : `&socialReason=${socialReason}`;

    const res = await fetch(
      `${URL_BASE}documentos/cxp${type}${startDateQuery}${endDateQuery}${socialReasonQuery}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return [];
  } catch (error) {
    Error(() => {}, error.message);
    return [];
  }
}

/**
 * Get a report of the CxP on the system
 * @param {import("./typesDocuments").CxpCxcReport} param - Params to get the information of the report
 * @returns {Promise<import("../../../../server/models/documentsV2Types").CxpReport>}
 */
export async function getCxcReport({
  endDate,
  startDate,
  socialReason = null,
}) {
  /**
   * @type {import("../../../../server/models/documentsV2Types").CxpReport}
   */
  const ERROR_DATA = {
    report: [],
    residue: {
      mxn: 0,
      usd: 0,
    },
    total: {
      mxn: 0,
      usd: 0,
    },
  };

  try {
    const startDateQuery = startDate === null ? "" : `&beginDate=${startDate}`;
    const endDateQuery = endDate === null ? "" : `&endDate=${endDate}`;
    const queryType = `?type=cxc`;
    const socialReasonQuery =
      socialReason === null || socialReason === ""
        ? ""
        : `&socialReason=${socialReason}`;

    const res = await fetch(
      `${URL_BASE}documentos/cxc${queryType}${startDateQuery}${endDateQuery}${socialReasonQuery}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      /**
       * @type {import("../../../../server/models/documentsV2Types").ResCxcReport}
       */
      const data = await res.json();
      return {
        report: data.report,
        residue: {
          mxn: data.totalResidueMxn,
          usd: data.totalResidueUsd,
        },
        total: {
          mxn: data.totalMxn,
          usd: data.totalUsd,
        },
      };
    }
    const data = await res.json();
    Error(() => {}, data.message);
    return ERROR_DATA;
  } catch (error) {
    Error(() => {}, error.message);
    return ERROR_DATA;
  }
}

/**
 * Get a report of the CxP on the system
 * @param {string} startDate - Start date for the fetching
 * @param {string} endDate - End date for the fetching
 * @param {"cxc"|"cxp"} type - Type of report to retrieve
 * @param {string|null} socialReason - Social reason for the filter
 * @returns {Promise<Blob|undefined>}
 */
export async function getCxpReportExcel(
  startDate,
  endDate,
  type,
  socialReason = null
) {
  try {
    const queryStartDate = startDate === null ? "" : `&startDate=${startDate}`;
    const queryEndDate = endDate === null ? "" : `&endDate=${endDate}`;
    const queryType = `?type=${type}`;
    const querySocialReason =
      socialReason === null || socialReason === ""
        ? ""
        : `&socialReason=${socialReason}`;

    const res = await fetch(
      `${URL_BASE}documentos/cxp/excel${queryType}${queryStartDate}${queryEndDate}${querySocialReason}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      const data = await res.blob();
      return data;
    }

    const data = await res.json();
    Error(() => {}, data.message);
    return undefined;
  } catch (error) {
    return undefined;
  }
}

/**
 *
 * @param {import("./typesDocuments").CxcCxpTypeReport} type - Type of report to fetch from the system
 * @param {import("./typesDocuments").CxpCxcReport} queryParams - Query params in order to make the fetch of the information
 * @returns {Promise<import("../../../../server/models/documentsV2Types").CxpReport>}
 */
export async function getCxcCxpReport(type, queryParams) {
  if (type === "cxp") {
    const data = await getCxpReport({ type, ...queryParams });
    return data;
  } else {
    const data = await getCxcReport({ type, ...queryParams });
    return data;
  }
}

/**
 * Get the report for the consecutive purchase order
 * @param {string} beginDate - Date start
 * @param {string} endDate - Date end
 * @param {number|undefined} [status=undefined] - Status
 * @param {string} [search=""] - Search
 * @returns {Promise<import("../../../../server/models/documentsV2Types").Odc[]>}
 */
export async function getConsecutivePurchaseOrder(
  beginDate,
  endDate,
  status = undefined,
  search = ""
) {
  try {
    const queryParamBeginDate = `?beginDate=${beginDate}`;
    const queryParamEndDate = `&endDate=${endDate}`;
    const queryParamStatus =
      typeof status === "number" ? `&status=${status}` : "";
    const queryParamSearch = search === "" ? "" : `&search=${search}`;

    const res = await fetch(
      `${URL_BASE}documentos/odc/consecutivo/reporte${queryParamBeginDate}${queryParamEndDate}${queryParamStatus}${queryParamSearch}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return [];
  } catch (error) {
    return [];
  }
}

/**
 * Get the information for the OC requested
 * @param {number} idOc - Id of the OC document
 * @returns {Promise<import("../../../../server/models/documentsV2Types").GetMoreInfoOdcResponseI|undefined>}
 */
export async function getMoreInfoConsecutiveOc(idOc) {
  try {
    const res = await fetch(
      `${URL_BASE}documentos/odc/consecutivo/masinfo?idDocument=${idOc}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      /**
       * @type {import("../../../../server/models/documentsV2Types").GetMoreInfoOdcResponseI}
       */
      const data = await res.json();

      /**
       * @type {import("../../../../server/models/documentsV2Types").GetMoreInfoOdcResponseI}
       */
      const parsedData = {
        ...data,
        invoice: data.invoice === null ? [] : data.invoice,
      };

      return parsedData;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
}

/**
 * Get the report for the consecutive purchase order (excel)
 * @param {string} beginDate - Date start
 * @param {string} endDate - Date end
 * @param {number|null|undefined} [status=undefined] - Status
 * @param {string} [search=""] - Search
 * @returns {Promise<any>}
 */
export async function getOcConsecutiveExcel(
  beginDate,
  endDate,
  status = undefined,
  search = ""
) {
  try {
    const queryParamBeginDate = `?beginDate=${beginDate}`;
    const queryParamEndDate = `&endDate=${endDate}`;
    const queryParamStatus =
      typeof status === "number" ? `&status=${status}` : "";
    const queryParamSearch = search === "" ? "" : `&search=${search}`;

    const res = await fetch(
      `${URL_BASE}documentos/odc/consecutivo/reporte/excel${queryParamBeginDate}${queryParamEndDate}${queryParamStatus}${queryParamSearch}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      const data = await res.blob();
      return data;
    }

    const data = await res.json();

    Error(() => {}, data.message);

    return undefined;
  } catch (error) {
    return undefined;
  }
}

/**
 * Get the report for the consecutive purchase order (excel)
 * @param {string} beginDate - Date start
 * @param {string} endDate - Date end
 * @param {number} idBankAccount - Id of the bank account to fetch
 * @returns {Promise<Blob|undefined>}
 */
export async function getBankMovementsExcel(beginDate, endDate, idBankAccount) {
  try {
    const res = await fetch(
      `${URL_BASE}bancos/v2/reportes/movimientos/excel?beginDate=${beginDate}&endDate=${endDate}&idBankAccount=${idBankAccount}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      const excel = await res.blob();
      return excel;
    }

    const data = await res.json();

    Error(() => {}, data.body);
    return undefined;
  } catch (error) {
    return undefined;
  }
}

/**
 * Get the table for the historic report requested
 * @param {import("./documentsTypes").ParamGetHistoricContract} param - Params to fetch the historic contract
 * @returns {Promise<import("../../../../server/models/documentsV2Types").GetContractReportResponseI[]|Blob|undefined>}
 */
export async function getHistoricContracts({
  executive = null,
  sector = null,
  socialReason = "",
  uen = null,
  excel = false,
}) {
  try {
    const queryParams = getQueryParams();

    const urlToUse = excel === true ? "/excel" : "";

    const res = await fetch(
      `${URL_BASE}documentos/contratos/reporte${urlToUse}${queryParams}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      if (!excel) {
        /**
         * @type {import("../../../../server/models/documentsV2Types").GetContractReportResponseI[]}
         */
        const data = await res.json();

        return data;
      }

      const file = await res.blob();
      return file;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }

  ////////////////////////////////////////////////////////////////////

  function getQueryParams() {
    // No filters requested, empty query param for URL
    if (
      executive === null &&
      sector === null &&
      socialReason === "" &&
      uen === null
    )
      return "";

    const queryParamExecutive =
      executive === null ? "" : `idExecutive=${executive}`;
    const queryParamSector = sector === null ? "" : `idSector=${sector}`;
    const queryParamUen = uen === null ? "" : `idUen=${uen}`;
    const queryParamSocialReason =
      socialReason === "" ? "" : `socialReason=${socialReason}`;

    const querys = [
      queryParamExecutive,
      queryParamSector,
      queryParamUen,
      queryParamSocialReason,
    ];

    const queryParamToUse = querys.reduce((url, queryParam, i) => {
      if (queryParam === "") return url;

      if (url === "") return `?${queryParam}`;

      return `${url}&${queryParam}`;
    }, "");

    return queryParamToUse;
  }
}

/**
 * @param {number} year - Year as YYYY
 * @param {number} executive - Id of the executive
 * @param {import("../../../../types/Sells/sells").FeeType} type - Type of fee to fetch
 * @returns {Promise<[]|import("../../../../types/Sells/sells").FeeExecutive>}
 */
export async function getFee(year, executive, type = "contribution") {
  try {
    const queryYear = `?year=${year}`;
    const queryExecutive = `&executive=${executive}`;
    const queryType = `&type=${type}`;

    const res = await fetch(
      `${URL_BASE}documentos/cuotas${queryYear}${queryExecutive}${queryType}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) return data;
  } catch (error) {
    return [];
  }
}

// http://localhost:8080/api/documentos/cuotas?year=2023&executive=14&type=contribution

/**
 * @param {number} year - Year as YYYY
 * @returns {Promise<import("../../../../types/Sells/sells").FeeDbItem|undefined>}
 */
export async function getFeeOverview(year) {
  try {
    const queryYear = `?year=${year}`;

    const res = await fetch(
      `${URL_BASE}documentos/cuotas-informacion${queryYear}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) return data;

    return undefined;
  } catch (error) {
    return undefined;
  }
}

/**
 * @param {number} year - Year as YYYY
 * @returns {Promise<import("../../../../types/Sells/sells").AbcFee>}
 */
export async function getFeeAbcParams(year) {
  /**
   * @type {import("../../../../types/Sells/sells").AbcFee}
   */
  const RESPONSE_DATA = {
    currency: null,
    power: null,
    type: null,
  };

  try {
    const queryYear = `?year=${year}`;

    const res = await fetch(
      `${URL_BASE}documentos/cuotas/parametros${queryYear}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) return data;

    return RESPONSE_DATA;
  } catch (error) {
    return RESPONSE_DATA;
  }
}

/**
 * Create a new fee for an executive
 * @param {number} year - Year
 * @param {import("../../../../types/Sells/sells").FeeType|string} type - Type of fee
 * @param {number} power - Power of the amount
 * @param {string} currency - Currency
 * @returns {Promise<boolean>} True if query was success
 */
export async function addFee(year, type, power, currency) {
  try {
    const res = await fetch(`${URL_BASE}documentos/cuotas`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        fee: {
          year,
          type,
          power,
          currency,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Create a new fee for an executive
 * @param {import("../../../../types/Sells/sells").DtoUpdateFee[]} fee - Information of the fee to add
 * @returns {Promise<boolean>} True if query was success
 */
export async function updateFee(fee = []) {
  try {
    const res = await fetch(`${URL_BASE}documentos/cuotas`, {
      credentials: "include",
      method: "PUT",
      body: JSON.stringify({ fee }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      Success(() => {}, data.message);
      return true;
    }

    Error(() => {}, data.message);
    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Get the available years for the combo
 * @returns {Promise<number[]>} True if query was success
 */
export async function getFeeComboYears() {
  try {
    const res = await fetch(`${URL_BASE}documentos/cuotas/fechas-disponibles`, {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await res.json();

    if (res.ok && isValidHttpResCode(res.status)) {
      return data;
    }

    Error(() => {}, data.message);
    return [];
  } catch (error) {
    return [];
  }
}

/**
 * Get the control report
 * @returns {Promise<Blob|null>} True if query was success
 */
export async function getControlReport() {
  try {
    const res = await fetch(`${URL_BASE}documentos/reporte/control`, {
      credentials: "include",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await res.blob();

    if (res.ok && isValidHttpResCode(res.status)) {
      saveAs(data, "Reporte de control.xlsx");
      return data;
    }

    return null;
  } catch (error) {
    return null;
  }
}

/**
 * Get the params for the documents template
 * @param {import("../../types/typedef/documents").DocumentI} document - Information of the document
 * @param {boolean} [useInvoiceTemplate=false] If true, in case of document id type = `2`. Instead use the invoice template
 *
 */
export async function getDocumentEmailTemplate(
  document,
  useInvoiceTemplate = false
) {
  let body = "";
  let subject = "";

  /**
   * @type {import("../../../../server/models/user/types").UserParsed}
   */
  let executive = undefined;

  let expirationSubject = "";

  /**
   * @type {import("pages/ProcesosEspeciales/Parameters/EnterpriseInfo/types").DtoEnterpriseInfo}
   */
  let enterprise = undefined;

  if (document === undefined)
    return {
      subject,
      body,
    };

  try {
    executive = await getUser(document.executive.id);
    enterprise = await getEnterpriseInfo();
  } catch (error) {}

  subject = `${enterprise.enterpriseName} | Envío de No. `;

  const phones = executive.contact.phone.lada + executive.contact.phone.number;

  if (document.type.id === 1) {
    subject += `COT-${parseToFolio(document.documentNumber)}`;

    body = `<p>Buen día ${document.contact.fullName}</p>
    </br>
    <p>Por este medio me es grato saludarle.</p>
    </br>
    <p>Basado en su solicitud de cotización, adjunto en este email nuestra propuesta.</p>
    <p>Por favor, avíseme si tiene alguna pregunta respondiendo a este correo electrónico o llamándome.</p>
    <p>Excelente día.</p>
    </br>
    <p>Ejecutivo: ${document.executive.fullName}
    Puesto: ${executive.rol.description}
    Teléfonos: ${phones}</p>
    `;
  }

  if (document.type.id === 2 && useInvoiceTemplate === false) {
    subject += `PED-${parseToFolio(document.documentNumber)}`;

    body = `<p>Buen día ${document.contact.fullName}</p>
    </br>
    <p>Por este medio me es grato saludarle.</p>
    </br>
    <p>Basado en su cotización, adjunto en este email la confirmación de su pedido. Por favor, avíseme si tiene alguna pregunta respondiendo a este correo electrónico o llamándome.
    </p>
    </br>
    <p>Excelente día.</p>
    </br>
    <p>Ejecutivo: ${document.executive.fullName}</p>
    <p>Puesto: ${executive.rol.description}</p>
    <p>Teléfonos: ${phones}</p>`;
  }

  if (document.type.id === 3) {
    subject += `ODC-${parseToFolio(document.documentNumber)}`;

    body = `<p>Buen día ${document.contact.fullName}</p>
    </br>
    <p>Por este medio me es grato saludarle.</p>
    </br>
    <p>Favor de procesar la ODC NO-${parseToFolio(document.documentNumber)}</p>
    <p>Se anexan formatos correspondientes a la orden con la información del cliente.</p>
    </br>
    <p>Quedo atento a la confirmación de este proceso.</p>
    </br>
    <p>Ejecutivo: ${document.executive.fullName}</p>
    <p>Correo: ${executive.contact.email}</p>
    <p>Teléfonos: ${phones}</p>`;
  }

  if (document.type.id === 2 && useInvoiceTemplate === true) {
    const xml = await downloadFileFromBlobV3(document.legal.xml.id);

    const readXml = await xmlFileToJson(xml);

    let expiration = "";

    try {
      expiration = `con fecha ${parseDateToText(new Date(readXml.$.Fecha))}`;

      expirationSubject = ` | Fecha vencimiento: ${parseDateToText(
        `${document.expiration.yyyymmdd}:`,
        "medium"
      )}`;
    } catch (error) {}

    subject += `FAC-${parseToFolio(+readXml.$.Folio)}`;

    body = `<p>Buen día ${document.contact.fullName}</p></br>
    <p>Por este medio me es grato saludarle.</p></br>

    <p>Por este medio le informamos que su factura ha sido emitida ${expiration} con un saldo a pagar de ${
      document.amount.total.text
    }  (*** ${numberToCurrencyTextFormat(
      document.amount.total.text,
      document.currency.code
    )} ***), la cual deberá ser liquidada antes de su fecha de vencimiento: ${parseDateToText(
      `${document.expiration.yyyymmdd}:`,
      "medium"
    )} </p>

    <p>Estamos anexando el documento PDF y XML de su factura.</p></br>

    <p>Los datos para depósito o transferencia de nuestras cuentas bancarias son los siguientes:</p>

    <p><b>BBVA BANCOMER MONEDA NACIONAL</b></p>
    <p>Beneficiario: GRUPO SAIKO</p>
    <p>Cuenta: 0453675084</p>
    <p>Clabe: 012580004536750846</p>
    <p>Moneda: PESOS</p>
    <p>SWIFT CODE: BCMRMXMMPYM</p></br>

    <p><b>BBVA BANCOMER DOLARES</b></p>
    <p>Beneficiario: GRUPO SAIKO</p>
    <p>Cuenta: 0136385893</p>
    <p>CLABE SPID: 012580001363858930</p>
    <p>Moneda: DOLARES</p></br>

    <p><b>BANCO MONEX SA. INSTITUCION DE BANCA MULTIPLE DOLARES</b></p>
    <p>Beneficiario: GRUPO SAIKO</p>
    <p>Cuenta: 02712578</p>
    <p>CLABE SPID:112962000027125787</p>
    <p>ABA o Routing: 112180</p>
    <p>SWIFT CODE: MONXMXMM</p>
    <p>Moneda: DOLARES</p>
    <p>Observación: DO NOT CONVERT</p>
    <p>Dirección del Banco:Paseo de la Reforma 284 Piso 15</p>
    <p>Col. Juárez Del Cuauhtémoc</p>
    <p>Cd de México CP 06600</p></br>

    <p>Tipo de cambio para pago: Si la factura a liquidar está en DOLARES, favor de liquidarla en su misma moneda, o su equivalente en pesos tomando el tipo de cambio a la venta, publicado en el portal de BANAMEX en la fecha de pago. FAVOR DE ENVIAR EL COMPORBANTE DE SU PAGO A comprobantefiscal@saiko.com.mx</p>

    <p>Para cualquier aclaración Contacte a:</p>
    <p>Yana Ivanova</p>
    <p>Administración</p>
    <p>81 8215-5100</p>
    <p>yivanova@saiko.mx</p> 
    `;
  }

  subject += ` | ${document.customer.shortName} ${expirationSubject}`;

  return {
    subject,
    body,
  };
}

/**
 *
 * @param {import("../../../../types/Sells/sells").QueryGetAuthInvoices} param - Params in order to
 * @returns {Promise<import("../../../../types/documents").AuthI>}
 */
export async function getAuthsInvoice({
  pagina = 1,
  ordernarPor = "id",
  registros = 100,
  desde,
  hasta = dateToDbFormat(new Date()),
  factura = undefined,
  pedido = undefined,
}) {
  /**
   * @type {import("../../../../types/documents").AuthI}
   */
  const ERROR_DATA = {
    auths: [],
    page: 1,
    pages: 0,
  };

  const facturaQueryParam =
    factura === undefined || factura === "" ? "" : `&factura=${factura}`;
  const pedidoQueryParam =
    pedido === undefined || pedido === "" ? "" : `&pedido=${pedido}`;

  try {
    const res = await fetch(
      `${URL_BASE}documentos/autorizaciones?pagina=${pagina}&ordernarPor=${ordernarPor}&registros=${registros}&desde=${desde}&hasta=${hasta}${facturaQueryParam}${pedidoQueryParam}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (res.ok && isValidHttpResCode(res.status)) {
      /**
       * @type {import("../../../../types/documents").AuthI}
       */
      const data = await res.json();
      return data;
    }

    const error = await getErrorData(res);
    Error(() => {}, error.message);

    return ERROR_DATA;
  } catch (error) {
    Error(() => {}, error.message);
    return ERROR_DATA;
  }
}

/**
 * Get the payment reminders
 * @param {import("../../../../types/reminderPayments").QueryGetReminderPayment} query - Params to fetch the reminders
 * @param {"excel"|"report"} [type="report"] - Get the type of response to obtain
 * @returns {Promise<import("../../../../types/reminderPayments").ReminderPaymentResI|Blob>} Response the query
 */
export async function getPaymentReminders(query, type = "report") {
  /**
   * @type {import("../../../../types/reminderPayments").ReminderPaymentResI}
   */
  const ERROR = {
    pages: 1,
    reminders: [],
  };

  const url = type === "report" ? "" : "/excel";

  try {
    const queryColumnOrder = `&columnOrder=${query.columnOrder}`;
    const queryOrderBy = `&orderBy=${query.orderBy === true ? "DESC" : "ASC"}`;
    const queryRowsPerPage =
      type === "report"
        ? `&rowsPerPage=${query.rowsPerPage}`
        : `&rowsPerPage=10000`;
    const queryPage = `&page=${query.page}`;
    const queryDate = `?date=${query.date}`;
    const queryTag = typeof query.tag !== "number" ? "" : `&tag=${query.tag}`;

    const response = await fetch(
      `${URL_BASE}documentos/recordatorio-cobranza${url}${queryDate}${queryPage}${queryRowsPerPage}${queryOrderBy}${queryColumnOrder}${queryTag}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (successRes(response)) {
      if (type === "report") {
        const data = await response.json();
        return data;
      } else {
        const excel = await response.blob();
        return excel;
      }
    }

    return ERROR;
  } catch (error) {
    return ERROR;
  }
}

/**
 * Get the available dates to display on the UI
 * @returns {Promise<Date[]>}
 */
export async function getPaymentReminderDates() {
  try {
    const response = await fetch(
      `${URL_BASE}documentos/recordatorio-cobranza/fechas`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    /**
     * @type {import("./documentsTypes").ResponsePaymentReminderDate}
     */
    const data = await response.json();

    if (successRes(response)) {
      if (Array.isArray(data)) {
        const dates = data.map(
          (value) => new Date(`${value.indexDate.split("T")[0]}:`)
        );
        return dates;
      }
    }

    Error(() => {}, data.message);

    return [];
  } catch (error) {
    return [];
  }
}

/**
 * Update the tag of a record
 * @param {number} id - Id of the invoice
 * @param {string} description - Description of the tag
 * @param {number|null} [idTag=null] - Id of the tag being updated (or created in case for null)
 * @returns {Promise<boolean>}
 */
export async function updateTag(id, description, idTag = null) {
  try {
    const response = await fetch(
      `${URL_BASE}documentos/recordatorio-cobranza/tag`,
      {
        credentials: "include",
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ idPaymentReminders: id, idTag, description }),
      }
    );

    const data = await response.json();

    if (successRes(response)) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
}

/**
 * Get the tags availables for the payment reminders
 * @returns {Promise<import("../../../../types/reminderPayments").TagReminderI[]>}
 */
export async function getTagsPaymentReminder() {
  try {
    const response = await fetch(
      `${URL_BASE}documentos/recordatorio-cobranza/tag`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    const data = await response.json();

    if (successRes(response)) return data;

    return [];
  } catch (error) {
    return [];
  }
}
// IvaMovementsResI

/**
 * Get the report for the movements
 * @param {number} year - Year requested
 * @param {number} month - Month requested
 * @param {"report"|"excel"} [type="report"] - Type of data to fetch
 * @returns {Promise<import("../../../../types/ivas/movements").IvaMovementsResI|Blob>}
 */
export async function getIvasReport(year, month, type = "report") {
  const dateRequested = new Date(`${year}-${month}-15`);

  const dateToFetch = getFirstDayOfMonth(dateRequested);

  const queryDate = `?date=${dateToDbFormat(dateToFetch)}`;

  const queryType = type === "report" ? "" : `/excel`;

  try {
    const response = await fetch(
      `${URL_BASE}ivas/movimientos${queryType}${queryDate}`,
      {
        credentials: "include",
        method: "GET",
      }
    );

    if (successRes(response)) {
      if (type === "excel") {
        const file = await response.blob();
        return file;
      }

      /**
       * @type {import("../../../../types/ivas/movements").IvaMovementsResI}
       */
      const data = await response.json();

      return data;

      // return [...data.ivaIncomes,...data.ivaOutcomes];
    }

    return [];
  } catch (error) {
    return [];
  }
}

/**
 *
 * @param {import("../../../../types/directory").CustomerExecutiveI[]} newAssociations
 */
export async function updateDocumentsExchange(newAssociations = []) {
  if (newAssociations.length <= 0) return;

  try {
    const response = await fetch(`${URL_BASE}directorio/cambio-ejecutivo`, {
      credentials: "include",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ customerExecutive: newAssociations }),
    });

    if (successRes(response)) {
      Success(() => {}, "Cambio de documentos actualizado");
      return true;
    }
    Error(() => {},
    "Favor de reportar a soporte, no se pudo hacer el cambio de ejecutivos");
    return false;
  } catch (error) {
    Error(() => {},
    "Favor de reportar a soporte, no se pudo hacer el cambio de ejecutivos");
    return false;
  }
}

export async function getInvoicesCancelated({
  beginDate,
  endDate
}) {

  const queryParams = arrayToQueryParam([
    `beginDate=${beginDate}`,
    `endDate=${endDate}`
  ]);

  try {
    const res = await axios.get(
      `${URL_BASE}administracion/facturas-emitidas/canceladas${queryParams}`,
      { withCredentials: true }
    );

    return res.data;
  } catch (error) {
    return [];
  }
}
